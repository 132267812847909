'use client'
import { Stack, Text, Title } from '@mantine/core'
import { useEffect } from 'react'

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return (
    <Stack>
      <Title order={3}>Something went wrong</Title>
      <Text>{error?.message}</Text>
    </Stack>
  )
}
